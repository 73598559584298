<template>
  <div>
    <div class="lg:hidden">
      <div class="px-6 py-2 flex flex-col flex-grow lg:mt-32 lg:min-h-50vh">
    <header class="py-1 text-lg flex font-semibold text-text-alternate-1 pr-6">
      <button @click="$router.go(-1)">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">Messages</h2>
    </header>
    <BPageLoader v-if="isLoading" class="flex-grow" />
    <div
      class="flex items-center flex-col justify-center"
      v-else-if="Object.keys(data).length === 0"
    >
      <div class="bg-bg-alternate-3 p-8 rounded-full mt-14 mb-10">
        <MessageIcon class="h-10 fill-current" />
      </div>
      <p class="font-semibold text-lg">No Messages</p>
    </div>
    <ul class="my-2" v-else>
      <li
        class="py-5 text-text-alternate-1 border-b border-border-primary"
        v-for="(msg, id) in data"
        :key="id"
      >
        <router-link :to="{ name: 'MessageChat', params: { id } }" class="w-full">
          <div class="text-2xs flex flex-col gap-1.5 flex-grow">
            <BFunctional
              :data="msg"
              :exec="(data) => Object.entries(data)[0]"
              v-slot="[subId, { message, createdAt }]"
            >
              <p class="font-bold">
                {{ msgSubjects.data.subjects[subId].description }}
              </p>
              <p class="truncate w-full text-text-alternate-3 font-medium">
                {{ message }}
              </p>
              <p class="flex gap-5 font-semibold mt-2">
                <span class="text-text-alternate-3">
                  {{ dayjs(createdAt).format('h:mma') }}
                </span>
                <span class="text-text-alternate-3">
                  {{ dayjs(createdAt).format('DD MMM YYYY') }}
                </span>
              </p>
            </BFunctional>
          </div>
        </router-link>
      </li>
    </ul>
    <router-link
      class="
        bg-btn-bg-primary
        text-btn-text-primary
        rounded
        sticky
        bottom-20
        font-bold
        text-xs
        py-2
        px-4
        flex
        items-center
        gap-2
        w-28
        ml-auto
        mt-auto
      "
      :to="{ name: 'ComposeMessage' }"
    >
      <MessageIcon class="h-4 fill-current" />
      Compose
    </router-link>
  </div>
    </div>
    <div class="hidden lg:flex justify-center pt-4">
      <div class="b-card mt-24 pt-8">
        <div class="px-26 py-2 flex flex-col flex-grow ">
    <header class="py-1 text-lg flex font-semibold text-text-alternate-1 pr-6">
      <button @click="$router.go(-1)">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">Messages</h2>
    </header>
    <BPageLoader v-if="isLoading" class="flex-grow" />
    <div
      class="flex items-center flex-col justify-center"
      v-else-if="Object.keys(data).length === 0"
    >
      <div class="bg-bg-alternate-3 p-8 rounded-full mt-14 mb-10">
        <MessageIcon class="h-10 fill-current" />
      </div>
      <p class="font-semibold text-lg">No Messages</p>
    </div>
    <ul class="my-2" v-else>
      <li
        class="py-5 text-text-alternate-1 border-b border-border-primary"
        v-for="(msg, id) in data"
        :key="id"
      >
        <router-link :to="{ name: 'MessageChat', params: { id } }" class="w-full">
          <div class="text-2xs flex flex-col gap-1.5 flex-grow">
            <BFunctional
              :data="msg"
              :exec="(data) => Object.entries(data)[0]"
              v-slot="[subId, { message, createdAt }]"
            >
              <p class="font-bold">
                {{ msgSubjects.data.subjects[subId].description }}
              </p>
              <p class="truncate w-full text-text-alternate-3 font-medium">
                {{ message }}
              </p>
              <p class="flex gap-5 font-semibold mt-2">
                <span class="text-text-alternate-3">
                  {{ dayjs(createdAt).format('h:mma') }}
                </span>
                <span class="text-text-alternate-3">
                  {{ dayjs(createdAt).format('DD MMM YYYY') }}
                </span>
              </p>
            </BFunctional>
          </div>
        </router-link>
      </li>
    </ul>
    <router-link
      class="
        bg-btn-bg-primary
        text-btn-text-primary
        rounded
        sticky
        bottom-20
        font-bold
        text-xs
        py-2
        px-4
        flex
        items-center
        gap-2
        w-28
        ml-auto
        mt-auto
      "
      :to="{ name: 'ComposeMessage' }"
    >
      <MessageIcon class="h-4 fill-current" />
      Compose
    </router-link>
  </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, computed } from 'vue';
import { useApi } from '@/cmp-functions/useApi';
import { getMessages } from '@/services/api';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import { useAppState } from '@/cmp-functions/appState.js';

export default {
  name: 'Messages',

  setup() {
    const member = { firstname: 'Admin' };
    const store = useStore();
    const authUser = computed(() => store.state.auth.authData);
    const [response, fetchUserMessages] = useApi(getMessages);
    fetchUserMessages(authUser.value.id);

    const { msgSubjects } = useAppState();

    return { ...toRefs(response), member, dayjs, msgSubjects };
  },
};
</script>
